<template>
  <v-dialog v-model="drawer" max-width="800" persistent scrollable>
    <v-form v-if="obModel" class="fill-height" @submit.prevent>
      <gw-form-observer
        :loading="isLoading"
        hide-top-actions
        lazy-validation
        @cancel="cancel"
        @save="save"
      >
        <settings v-model="obModel" />
      </gw-form-observer>
    </v-form>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Mixins, PropSync } from "vue-property-decorator";
import AccountreceiptsMixin from "@/modules/accountreceipts/mixins/AccountreceiptsMixin";
import Settings from "@/modules/accountreceipts/components/tabs/Settings.vue";

@Component({
  components: {
    Settings,
  },
})
export default class AccountreceiptsForm extends Mixins(AccountreceiptsMixin) {
  @PropSync("open", { type: Boolean, default: false }) drawer!: boolean;
}
</script>
