<template>
  <v-col cols="12">
    <v-row>
      <v-col cols="12">
        <data-table
          :headers="headers"
          :items="obCollection.getModelList()"
          :options="pagination"
          :total="serverItemsLength"
          :last-page="serverLastPage"
          :loading="bLocalLoading"
          btn-action-path="accountreceipts"
          btn-action-item-key-id="id"
          hide-btn-action-view
          is-route-name
          @delete="deleteItem"
          @update:sort-by="onSortBy"
          @update:sort-desc="onSortDesc"
          @update:options="onPagination"
          @update:page="onPage"
        >
          <template #[`item.code`]="{ item }">
            <v-btn
              :to="{ name: 'accountreceipts.update', params: { id: item.id } }"
              color="primary"
              text
            >
              {{ item.code }}
            </v-btn>
          </template>

          <template #[`item.actions`]="{ item }">
            <module-actions :value="item.id" @delete="deleteItem" />
          </template>
        </data-table>
      </v-col>
    </v-row>
    <accountreceipts-form
      v-model="obItem"
      :open.sync="displayForm"
      :key="$route.params.id"
    />
  </v-col>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import AccountreceiptsMixin from "@/modules/accountreceipts/mixins/AccountreceiptsMixin";
import ActiveIcon from "@/components/common/ActiveIcon.vue";
import AccountreceiptsForm from "@/modules/accountreceipts/components/Form.vue";
import type { DataTableHeader } from "@/mixins/DataTableMixin";

@Component({
  components: {
    ActiveIcon,
    AccountreceiptsForm,
  },
})
export default class AccountreceiptsList extends Mixins(AccountreceiptsMixin) {
  isList = true;

  onMounted() {
    const arHeaders: DataTableHeader[] = [
      { text: "account.code", value: "code" },
      { text: "account.name", value: "account.name" },
      { text: "name", value: "name" },
      { text: "cfecode", value: "cfecode.code" },
    ];
    this.setDTHeaders(arHeaders).addDTActionsHeader();
    this.index();
  }

  onRegisterEvents() {
    this.addEvent("app.company.selected", this.index);
  }
}
</script>
